





























































import { Component, Vue, Watch } from "vue-property-decorator";
import { api } from "@/api";
import { appName } from "@/env";
import { readLoginError } from "@/store/main/getters";
import {
  dispatchSignup,
  dispatchGoogleLogin,
  dispatchFacebookLogin,
  dispatchAppleLogin,
  dispatchSignupVerify,
} from "@/store/main/actions";
import VuetifyGoogleAutocomplete from "./VuetifyGoogleAutocomplete.vue";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import FeasibilityAddress from "./FeasibilityAddress.vue";
import FeasibilityInfo from "./FeasibilityInfo.vue";
import FeasibilityMap from "./FeasibilityMap.vue";
import Loading from "./Loading.vue";
import TCSignup from "./TCSignup.vue";
import TCPSignup from "./TCPSignup.vue";
import { Providers } from "universal-social-auth";
import { readUserProfile } from "@/store/main/getters";
import UniversalSocialauth from "universal-social-auth";
// Button M2
import {
  Github,
  Facebook,
  Google,
  Twitter,
  Apple,
} from "universal-social-auth";
import { googleClientId, appUrl, facebookClientId } from "@/env";
import axios from "axios";
import Social from "./Social.vue";

@Component({
  components: {
    VuetifyGoogleAutocomplete,
    Header,
    Footer,
    Loading,
    TCSignup,
    TCPSignup,
    Social,
    FeasibilityAddress,
    FeasibilityInfo,
    FeasibilityMap,
  },
  props: {
    editInfo: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
    },
  },
})
export default class Apply extends Vue {
  public valid = true;
  public email = "";
  public password = "";
  public password2 = "";
  public appName = appName;
  public unit = "";
  public address = "";
  public firstName = "";
  public lastName = "";
  public phoneNumber = "";
  public place = "";
  public placeId = "";
  public addressInfo = "";
  public addressData = {
    latitude: 14.5547,
    longitude: 121.0244,
    needsInput: true,
  };
  public agree = false;
  public showLoading = false;
  public dialogMini = false;
  public dialogMini2 = false;
  public email2 = "";
  public passwordError = "";
  public phoneError = "";
  public referralError = "";
  public referral = "";
  public referral_cid = "";
  public referral_items = [
    "Building Admin",
    "Online Ads",
    "Flyers",
    "Google Search",
    "Referred by Friend",
    "Referred by Broker",
    "Referred by Horizon Chat Assistant",
    "Sales Booth",
    "Other",
  ];
  public referral_other = "";
  public mounted() {
    if (this.userProfile) {
      if (this.userProfile.first_name) {
        this.firstName = this.userProfile.first_name;
      }
      if (this.userProfile.last_name) {
        this.lastName = this.userProfile.last_name;
      }
      if (this.userProfile.email) {
        this.email = this.userProfile.email;
      }
      if (this.userProfile.phone) {
        this.phoneNumber = this.userProfile.phone;
      }
    }

    if (this.$route.query.referral_cid) {
      this.$cookies.config("999d");
      this.$cookies.set("referral_cid", this.$route.query.referral_cid);
      this.referral = "Referred by Friend";
      this.referral_cid = this.$route.query.referral_cid;
    }

    if (this.$cookies.isKey("referral_cid")) {
      this.referral = "Referred by Friend";
      this.referral_cid = this.$cookies.get("referral_cid");
      if (this.referral_cid === "1121") {
        this.referral = "Referred by Horizon Chat Assistant";
      }
    }
  }

  public get loginError() {
    return readLoginError(this.$store);
  }

  public validate() {
    return (
      !!this.email &&
      !!this.password &&
      !!this.password2 &&
      !!this.firstName &&
      !!this.lastName &&
      !!this.phoneNumber &&
      !!this.referral &&
      !this.phoneError &&
      this.checkPassword() &&
      this.checkReferral() &&
      this.password === this.password2 &&
      !!this.agree
    );
  }

  public checkSelect(input) {
    if (!input) {
      return false;
    } else {
      return true;
    }
  }
  public checkPhone(input) {
    setTimeout(() => {
      this.phoneNumber = this.phoneNumber.replace(/[^\d\(\)\+\-]/gi, "");
      this.phoneNumber = this.phoneNumber.replace(/[\+]+/gi, "+");
      this.phoneNumber = this.phoneNumber.replace(/\+\(/gi, "+");
      this.phoneNumber = this.phoneNumber.replace(/[\(]+/gi, "(");
      this.phoneNumber = this.phoneNumber.replace(/[\)]+/gi, ")");
      this.phoneNumber = this.phoneNumber.replace(/[\-]+/gi, "-");
      this.phoneNumber = this.phoneNumber.replace(/\)\(/gi, ")");
      if (this.phoneNumber) {
        const phoneCheck = this.phoneNumber;
        const check = phoneCheck.replace(/\D/gi, "");
        if (check.length < 8) {
          this.phoneError =
            "Please ensure the phone number is at least 8 digits";
          return false;
        } else if (check.length > 15) {
          this.phoneError =
            "Please ensure the phone number is at most 15 digits";
          return false;
        } else {
          this.phoneError = "";
        }
      }
    }, 100);
  }

  public checkPassword() {
    if (this.password) {
      if (this.password.length < 8) {
        this.passwordError =
          "Please ensure the password is at least 8 characters";
        return false;
      } else if (!this.password.match(/[A-Z]/)) {
        this.passwordError =
          "Please ensure the password has at least 1 capital letter";
        return false;
      } else if (!this.password.match(/\d/)) {
        this.passwordError = "Please ensure the password has at least 1 number";
        return false;
      }
      this.passwordError = "";
      return true;
    } else {
      return false;
    }
  }

  public checkReferral() {
    if (!this.referral) {
      this.referralError = "Please Select a Referral Source";
      return false;
    } else {
      this.referralError = "";
      return true;
    }
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }
  public getAddressData(addressData, placeResultData, id) {
    this.addressData = addressData;
    this.place = placeResultData;
    this.placeId = id;
    this.addressInfo = `Address Detail: ${JSON.stringify(
      addressData
    )} ${JSON.stringify(placeResultData)} ${id}`;
  }

  public updateAddress(newAddress) {
    this.address = newAddress;
  }
  public submit() {
    this.showLoading = true;
    let referral_cid = "";
    if (this.$cookies.isKey("referral_cid")) {
      referral_cid = this.$cookies.get("referral_cid");
    }
    if (this.userProfile) {
      dispatchSignupVerify(this.$store, {
        firstName: this.firstName,
        lastName: this.lastName,
        phone: this.phoneNumber,
        email: this.email,
        password: this.password,
        referral_cid: referral_cid,
        referral: this.referral,
        referral_other: this.referral_other,
        unit: this.unit,
        address: this.address,
        addressData: `${JSON.stringify(this.addressData)}`,
        placeData: `${JSON.stringify(this.place)}`,
        placeId: `${JSON.stringify(this.placeId)}`,
      }).catch((error) => {
        this.showLoading = false;
      });
    } else {
      dispatchSignup(this.$store, {
        checkAddress: false,
        firstName: this.firstName,
        lastName: this.lastName,
        unit: this.unit,
        address: this.address,
        addressData: `${JSON.stringify(this.addressData)}`,
        placeData: `${JSON.stringify(this.place)}`,
        placeId: `${JSON.stringify(this.placeId)}`,
        phone: this.phoneNumber,
        email: this.email,
        password: this.password,
        referral_cid: referral_cid,
        referral: this.referral,
        referral_other: this.referral_other,
      }).catch((error) => {
        this.showLoading = false;
      });
    }
  }

  public showAgreeTC(toggle) {
    this.dialogMini2 = toggle;
    if (!toggle) {
      this.agree = true;
    }
  }

  public showAgreePP(toggle) {
    this.dialogMini = toggle;
    if (!toggle) {
      this.agree = true;
    }
  }
}
