











































import { Component, Vue, Watch } from "vue-property-decorator";
import { api } from "@/api";
import { appName } from "@/env";
import { readLoginError } from "@/store/main/getters";
import {
  dispatchCheckAddress,
  dispatchGetServiceAddress,
} from "@/store/main/actions";
import VuetifyGoogleAutocomplete from "./VuetifyGoogleAutocomplete.vue";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import Progress from "./Progress.vue";
import _ from "lodash";
import { formatAddress } from "../utils";
import Loading from "./Loading.vue";

@Component({
  components: { VuetifyGoogleAutocomplete, Header, Footer, Progress, Loading },
  props: {
    editInfo: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
    },
    onAddress: {
      type: Function,
    },
  },
})
export default class FeasibilityAddress extends Vue {
  public valid = true;
  public appName = appName;
  public unit = "";
  public address = "";
  public place = "";
  public placeId = "";
  public addressInfo = "";
  public addressData = {
    latitude: 14.5547,
    longitude: 121.0244,
    needsInput: true,
  };
  public showLoading = false;
  public tip = "";

  $refs!: Vue["$refs"] & {
    map: typeof VuetifyGoogleAutocomplete;
  };
  public async mounted() {
    if (this.$cookies.isKey("address")) {
      this.addressData = this.$cookies.get("addressData");
      this.placeId = this.$cookies.get("placeId");
      this.place = this.$cookies.get("placeData");
      this.unit = this.$cookies.get("unit");
      this.address = this.$cookies.get("address");
      console.log("place", this.place);
      setTimeout(() => {
        this.$refs.map.update(this.address);
      }, 100);
      this.$props.onAddress(
        this.addressData,
        this.place,
        this.placeId,
        this.tip,
        this.unit,
        this.address
      );
    }

    await dispatchGetServiceAddress(this.$store, (response) => {
      if (_.get(response, "address1", null)) {
        this.unit = _.get(response, "unit", "");
        this.address = formatAddress(response);
        const googleAddress = _.get(response, "google_address_json", null);
        const place = _.get(response, "google_place_json", null);
        const placeId = _.get(response, "google_place_id", null);
        if (googleAddress) {
          this.addressData = JSON.parse(googleAddress);
        }
        if (place) {
          this.place = JSON.parse(place);
        }
        if (placeId) {
          this.placeId = placeId;
        }
        this.$refs.map.update(this.address);
      }
    });
  }
  public get loginError() {
    return readLoginError(this.$store);
  }

  public validate() {
    if (this.address) {
      const addresses = this.address.split(", ");
      if (addresses.length < 3) {
        this.tip = "Please select your address from the address selection menu";
      } else {
        this.tip = "";
      }
    }
    this.sendAddress();
    return !this.tip && !!this.address && !!this.unit;
  }

  public getAddressData(addressData, placeResultData, id) {
    this.addressData = addressData;
    this.place = placeResultData;
    this.placeId = id;
    this.addressInfo = `Address Detail: ${JSON.stringify(
      addressData
    )} ${JSON.stringify(placeResultData)} ${id}`;
    this.sendAddress();
  }

  public sendAddress() {
    if (this.$props.onAddress) {
      this.$props.onAddress(
        this.addressData,
        this.place,
        this.placeId,
        this.tip,
        this.unit,
        this.address
      );
    }
    this.$cookies.config("999d");
    if (this.addressData) this.$cookies.set("addressData", this.addressData);
    if (this.place)
      this.$cookies.set("placeData", {
        address_components: this.place.address_components,
        formatted_address: this.place.formatted_address,
      });
    if (this.placeId) this.$cookies.set("placeId", this.placeId);
    if (this.address) this.$cookies.set("address", this.address);
    if (this.unit) this.$cookies.set("unit", this.unit);
  }
  public updateAddress(newAddress) {
    this.address = newAddress;
    this.sendAddress();
  }
  public submit() {
    this.showLoading = true;
    dispatchCheckAddress(this.$store, {
      unit: this.unit,
      address: this.address,
      addressData: `${JSON.stringify(this.addressData)}`,
      placeData: `${JSON.stringify(this.place)}`,
      placeId: `${JSON.stringify(this.placeId)}`,
    }).catch((error) => {
      this.showLoading = false;
      this.tip = "Please select your address from the address selection menu";
    });
  }
}
