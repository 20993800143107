var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"ContainerImg"},[_c('div',{staticClass:"MapBorderImg"},[_c('GmapMap',{staticStyle:{"width":"100%","height":"233px","border-radius":"30px"},attrs:{"center":!!this.$props.addressData && !this.$props.addressData.needsInput
          ? {
              lat: this.$props.addressData.latitude,
              lng: this.$props.addressData.longitude,
            }
          : _vm.PHILIPPINES,"zoom":!!this.$props.addressData && !this.$props.addressData.needsInput
          ? 18
          : 10,"map-type-id":"terrain"}},_vm._l(([
          {
            position:
              !!this.$props.addressData && !this.$props.addressData.needsInput
                ? {
                    lat: this.$props.addressData.latitude,
                    lng: this.$props.addressData.longitude,
                  }
                : _vm.PHILIPPINES,
          } ]),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position,"clickable":true,"draggable":false}})}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }