










































import { Component, Vue, Watch } from "vue-property-decorator";
import { api } from "@/api";
import { appName } from "@/env";
import { readLoginError } from "@/store/main/getters";
import {
  dispatchCheckAddress,
  dispatchGetServiceAddress,
} from "@/store/main/actions";
import VuetifyGoogleAutocomplete from "./VuetifyGoogleAutocomplete.vue";
import Progress from "./Progress.vue";
import _ from "lodash";
import { formatAddress } from "../utils";
import Loading from "./Loading.vue";

@Component({
  components: { Progress, Loading },
  props: {
    addressData: {
      type: Object,
    },
    editInfo: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
    },
  },
})
export default class FeasibilityMap extends Vue {
  public valid = true;
  public appName = appName;
  public unit = "";
  public address = "";
  public place = "";
  public placeId = "";
  public addressInfo = "";
  public showLoading = false;
  public tip = "";
  public PHILIPPINES = {
    lat: 14.5547,
    lng: 121.0244,
  };
}
